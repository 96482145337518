import reducers from "./reducers";

const paymentReducers = {
  allPaymentPlans: reducers.allPaymentPlansReducer,
  paymentConfirmation: reducers.paymentConfirmationReducer,
  paymentVerification: reducers.paymentVerificationReducer,
  paymentProviders: reducers.paymentProvidersReducer,
};

export { paymentReducers };
