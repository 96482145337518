import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { fullUrlAction } from "../../../redux/action";
import { OFFLINE_BATCH_TICKETS } from "../../../redux/events/types";
import "date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { primaryColor } from "../../../assets/styles/base";
import "../../../assets/styles/plugins/calendar.css";
import { Grid } from "@material-ui/core";
import Loading from "../../../components/isLoading";
import Toast from "../../../components/MessageToast";
import fullUrlApiService from "../../../services/fullUrlService";
import TextField from "@material-ui/core/TextField";
import "../../../assets/styles/components/invitations.css";
import CloudUploadTwoToneIcon from '@material-ui/icons/CloudUploadTwoTone';
import FileUpload from "../../../components/CustomUpload/fileUpload";

const userStyles = makeStyles((theme) => ({
  card: {
    padding: 10,
    width: "100%",
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },

  checkBox: {
    "&.Mui-checked": {
      color: primaryColor[0],
    },
  },
  TicketsInput: {
    display: "flex",
    width: "100%",
    "@media(max-width:1300px)": {
      flexDirection: "column",
      margin: "10px 0px 10px 0px",
      width: "100%",
    },
  },
  listItem: {
    paddingRight: "0px",
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  childList: {
    paddingRight: "0px",
    paddingLeft: "0px",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  Itemcontainer: {
    maxWidth: "85%",
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  batchesList: {
    width: "500px",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  actions: {
    display: "block",
    "@media(max-width:600px)": {
      display: "none",
    },
  },
}));

export default ({ event, apiCall }) => {
  const classes = userStyles();
  const generatedTickets = useSelector((store) => store.offlineTickets);
  const [nav, setNav] = useState(false);
  const batches = useSelector((state) => state.offlineTicketBatches);

  const [batchDetail, setBatchDetail] = React.useState([]);
  const [state, setState] = useState({
    invitationFileUrl: "",
    destination: "",
    ispublic: false,
  })

  const handleFileUploaded = (fileUrl) => {
    setState(prevState => ({ ...prevState, invitationFileUrl: fileUrl }));
  }

  React.useEffect(() => {
    const tempBatchDetails =
      !batches.loading &&
      batches.data.results &&
      batches.data.results.map((batch) => {
        const batchDetail = [];

        for (const detail of batch.batchDetail) {
          const index = batchDetail.map((x) => x.name).indexOf(detail.name);
          if (index !== -1) {
            batchDetail[index].quantity += detail.quantity;
          } else {
            batchDetail.push(detail);
          }
        }

        batch.batchDetail = batchDetail;

        return batch;
      });
    setBatchDetail(tempBatchDetails ? tempBatchDetails : []);
  }, [batches]);
  useEffect(() => {
    nav &&
      !generatedTickets.loading &&
      generatedTickets.data.status === 201 &&
      Toast({
        message: `${generatedTickets.data.message}`,
        type: "success",
      });
    nav &&
      !generatedTickets.loading &&
      generatedTickets.data.status !== 201 &&
      Toast({
        message: `${generatedTickets.data.message}`,
        type: "error",
      });
    setNav(false);
  }, [!generatedTickets.loading && nav]);

  const [open, setOpen] = React.useState(false);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);


  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.name.endsWith(".pdf")) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      alert("Please select an xlsx file");
    }
  };

  const handleUpload = () => {
    setSelectedFile("");
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "10px" }}
      >
        <Grid item>
          <Typography variant="h6" align="start">
            Upload Event Invitations
          </Typography>
        </Grid>
      </Grid>
      <br></br>
      <FileUpload onFileUploaded={handleFileUploaded} event={event} isFileUpload defaultImg="" destination="invitations" />
      {/* <div style={{width:"fit-content"}}>
        <div style={{display:"flex", alignItems:"center"}}>
          <label htmlFor="file-input" className="upload-btn upload-label">
            <span style={{marginRight:"10px"}}>Upload Invitations File</span> <CloudUploadTwoToneIcon />
          </label>
          <span className="file-name">{selectedFile ? selectedFile.name : ""}</span>
          <input
            id="file-input"
            type="file"
            onChange={handleFileChange}
            accept=".pdf"
          />
        </div>
        <div>
          <button disabled={!selectedFile} onClick={handleUpload} className="upload-btn inv-btn">
            Upload File
          </button>
          <button disabled={!selectedFile} onClick={handleUpload} className="upload-btn inv-btn">
            Generate Invitations
          </button>
        </div>
      </div> */}
    </Card>
  );
};
