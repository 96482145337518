import React, { useState, useEffect } from "react";
import Lodash, { set } from "lodash";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import {
  Collapse,
  ListSubheader,
  Divider,
} from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
// import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import CustomInput from "../../../components/CustomInput/TextField";
import { Grid } from "@material-ui/core";
import Remove from "@material-ui/icons/RemoveCircle";


export default ({
  paymentConfig,
  classes,
  onPaymentRadioChange,
  onPayMethodsChange,
  setPaymentConfig,
  configuredCountries,
  paymentObject
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [state, setState] = useState({
    activeTab:
      (selectedValues && selectedValues[0]) ||
      "RWF",
  });
  const concatenatedArray = paymentConfig.operators ? paymentConfig.operators.concat(paymentObject) : paymentObject;

  // Remove duplicates based on the specified conditions
  let resultArray = Lodash.uniqWith(concatenatedArray, (a, b) => {
    return a.name === b.name && a.method === b.method && a.providerId === b.providerId;;
  });

  // Iterate through the resultArray and modify the objects based on the conditions
  resultArray.forEach(obj => {
    if (obj.voteOptions) {
      obj.voteOptions = Lodash.filter(obj.voteOptions, 'amount');
    }

    if (obj.countries && obj.countries.length > 0) {
      obj.countries = obj.countries.filter(country => country.voteOptions || !obj.voteOptions);
    }

    if (obj.currencies && obj.currencies.length > 0) {
      obj.currencies = obj.currencies.filter(currency => currency.voteOptions || !obj.voteOptions);
    }
  });

  const [configured, setConfigured] = useState([]);
  const [amount, setAmount] = useState('');
  const [votes, setVotes] = useState('');
  const [cardAmount, setCardAmount] = useState('');
  const [cardVotes, setCardVotes] = useState('');


  const countries = Lodash.compact(Lodash.uniqBy(Lodash.flatMap(resultArray, 'countries'), 'code'));

  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [voteOptions, setVoteOptions] = React.useState([]);

  const handleChange = (event) => {
    const selectedCountryObject = countries.find(
      (country) => country.name === event.target.value
    );
    setSelectedCountry({ ...selectedCountryObject });
  };

  const handleConfigured = () => {
    setConfigured([...configured, { ...selectedCountry, voteOptions: voteOptions }]);
    setSelectedCountry('');
    setVoteOptions([]);
    configuredCountries([...configured, { ...selectedCountry, voteOptions: voteOptions }]);
  }

  const [currenciesConfig, setCurrenciesConfig] = useState([]);

  useEffect(() => {
    const preselectedValues = currenciesConfig
      .filter((item) => item.voteOptions && item.voteOptions.length > 0)
      .map((item) => item.code);
    setSelectedValues(preselectedValues);
    setPaymentConfig({
      ...paymentConfig,
      operators: resultArray
    })

  }, [currenciesConfig]);

  useEffect(() => {
    // Fetch or set the currencyConfig data from your source
    setCurrenciesConfig(currencyConfig);
    setPaymentConfig({
      ...paymentConfig,
      operators: resultArray
    })
  }, []);

  useEffect(() => {
    configured.forEach((configuredCountry) => {
      resultArray.forEach((resultItem) => {
        if (resultItem.countries) {
          const countryToUpdate = resultItem.countries.find((country) => country.code === configuredCountry.code);
          if (countryToUpdate) {
            countryToUpdate.voteOptions = configuredCountry.voteOptions;
          }
        } else if (resultItem.currencies) {
          const currencyToUpdate = resultItem.currencies.find((currency) => currency.code === configuredCountry.code);
          if (currencyToUpdate) {
            currencyToUpdate.voteOptions = configuredCountry.voteOptions;
          }
        }
      });
    });
    setPaymentConfig({
      ...paymentConfig,
      operators: resultArray,
      changed: true
    })
  }, [configured])

  const handleChangeCurrency = (event) => {
    const newSelectedValues = event.target.value;
    setSelectedValues(newSelectedValues);
  };

  const handleConfigureVoteOptions = (currencyCode, newVoteOptions) => {
    const newCurrencyConfig = currencyConfig.map((currency) => {
      if (currency.code === currencyCode) {
        return {
          ...currency,
          voteOptions: newVoteOptions,
        };
      }
      return currency;
    });
    setCurrenciesConfig(newCurrencyConfig);
  };

  const [collapse, setCollapse] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(true);


  const handleCollapse = (index) => {
    if (index === selectedIndex) {
      setSelectedIndex("");
      setCollapse(!collapse);
    } else {
      setSelectedIndex(index);
      setCollapse(!collapse);
    }
  };

  const handleAdd = () => {
    const newVoteOption = { amount, votes };

    // Add the new vote option to the existing array of vote options
    setVoteOptions([...voteOptions, newVoteOption]);
  }

  const [configuredCurrencies, setConfiguredCurrencies] = useState([]);
  // const [configuredCountries, setConfiguredCountries] = useState([];)


  const handeCardCurrencies = (activeCurrency) => {
    const newCardVoteOptions = { cardAmount, cardVotes };
    resultArray.map((item) => {
      if (item.method === 'card' && Array.isArray(item.currencies)) {
        const currency = item.currencies.find((currency) => currency.code === activeCurrency);
        if (currency && Array.isArray(currency.voteOptions)) {
          currency.voteOptions.push(newCardVoteOptions);
        }
      }
      return item;
    });
    setCardAmount('');
    setCardVotes('');
    setConfiguredCurrencies(currencyConfig)
  }

  const handleRemoveOption = index => {
    const updatedVoteOptions = [...voteOptions];
    updatedVoteOptions.splice(index, 1);
    setVoteOptions(updatedVoteOptions);
  };

  const handleRemoveCardOption = (clickedVoteOption, index) => {

    const clickedCardAmount = clickedVoteOption.cardAmount;
    const clickedCardVotes = clickedVoteOption.cardVotes;

    // resultArray.map((item) => {
    // if (item.method === 'card' && Array.isArray(item.currencies)) {
    const currency = currencyConfig.find((currency) => currency.code === state.activeTab);
    if (currency && Array.isArray(currency.voteOptions)) {
      // currency.voteOptions.push(newCardVoteOptions);

      // Find the index of the clicked vote option
      const clickedIndex = currency.voteOptions.findIndex((voteOption) => {
        return voteOption.cardAmount === clickedCardAmount && voteOption.cardVotes === clickedCardVotes;
      });

      // Remove the clicked vote option from the array
      if (clickedIndex !== -1) {
        currency.voteOptions.splice(clickedIndex, 1);
      }
    }
    const updatedVoteOptions = [...configuredCurrencies];
    updatedVoteOptions.splice(index, 1);
    setConfiguredCurrencies(updatedVoteOptions);
  }

  const countriesWithVoteOptions = Lodash.flatMap(paymentConfig.operators, 'countries')
    .filter((country) => country && country.voteOptions && country.voteOptions.length > 0);

  // Remove duplicates from the array based on the country code
  const countriesConfig = Lodash.uniqBy(countriesWithVoteOptions, 'code');

  useEffect(() => {
    setPaymentConfig({
      ...paymentConfig
    })
    setConfigured(configured.concat(countriesConfig));
  }, [])

  const currencyWithVoteOptions = Lodash.flatMap(paymentConfig.operators, 'currencies')
    .filter((currency) => currency && currency.voteOptions) || [];

  // Remove duplicates from the array based on the country code
  const currencyConfig = Lodash.uniqBy(currencyWithVoteOptions, 'code');

  const handleRemoveCountry = (index) => {
    // const dataValues = countriesConfig
    const updatedCountries = [...configured];
    updatedCountries.splice(index, 1);
    setConfigured(updatedCountries);
  }
  console.log(paymentConfig.methods, "===");
  return (
    <div
      style={{
        backgroundColor: "rgba(0,0,0,.02)",
        padding: 10,
        marginTop: 10,
        marginBottom: 10,
      }}
    >
      <ListItem>
        <ListItemText primary="Payment options" />
      </ListItem>

      <ListItem>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment Required?</FormLabel>
          <RadioGroup
            aria-label="payment options"
            defaultValue="Not required"
            name="payment"
            row
            onChange={onPaymentRadioChange}
          >
            <FormControlLabel
              value="required"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={paymentConfig.required}
                />
              }
              label="Required"
            />
            <FormControlLabel
              value="notrequired"
              control={
                <Radio
                  className={classes.checkBox}
                  checked={!paymentConfig.required}
                />
              }
              label="Not required"
            />
          </RadioGroup>
        </FormControl>
      </ListItem>
      {paymentConfig.required && (
        <React.Fragment>
          <ListItem>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="paymentMethods">Payment methods</InputLabel>
              <Select
                labelId="paymentMethods"
                id="paymentMethods"
                name="paymentMethods"
                onChange={onPayMethodsChange}
                label="Payment methods"
                multiple
                value={paymentConfig.methods && paymentConfig.methods}
                renderValue={(selected) => selected.join(", ")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="MobileMoney">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.methods &&
                      paymentConfig.methods.indexOf("MobileMoney") > -1
                    }
                  />
                  <ListItemText primary={"MobileMoney"} />
                </MenuItem>
                <MenuItem value="card">
                  <Checkbox
                    className={classes.checkBox}
                    checked={
                      paymentConfig.methods &&
                      paymentConfig.methods.indexOf("card") > -1
                    }
                  />
                  <ListItemText primary={"card"} />
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>

          <ListItem>
            <Grid
              container
              spacing={2}
              justifyContent="space-around"
              style={{ marginTop: 10 }}
            >
              {paymentConfig.methods &&
                paymentConfig.methods.indexOf("MobileMoney") > -1 && (
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{
                      border: "1px solid black",
                      borderRadius: 5,
                      marginBottom: 5,
                      height: "fit-content",
                    }}
                  >
                    Mobile money options
                    <br />
                    <br />
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="cardCurrency">
                        Supported countries
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        MenuProps={{
                          getContentAnchorEl: null,
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                          },
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                              width: 250,
                            },
                          },
                        }}
                        value={selectedCountry ? selectedCountry.name : ''}
                        onChange={handleChange}
                      >
                        {countries.map(country => <MenuItem disabled={configured.some((disabledCountry) => disabledCountry.name === country.name)} value={country.name}>{country.name}</MenuItem>)}
                      </Select>
                    </FormControl>
                    <Typography>Votes options</Typography>
                    <List>
                      {voteOptions.map((el, index) => (
                        <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                          <ListItemText
                            primary={`> ${selectedCountry.currency || 'RWF'} ${el.amount}: ${el.votes} votes `}
                          />
                          <ListItemSecondaryAction
                            onClick={() => {
                              handleRemoveOption(index)
                            }}
                          >
                            <Tooltip
                              title={`Remove ${el.votes} option`}
                              arrow
                            >
                              <Remove fontSize="small" color="action" />
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}

                      <ListItem>
                        <Grid container spacing={1}>
                          <Grid item sm>
                            <CustomInput
                              label="Amount"
                              value={amount || ""}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              fullWidth
                              type="number"
                              StartAdornment={() => `${selectedCountry.currency || 'RWF'}: `}
                            />
                          </Grid>
                          <Grid item sm>
                            <CustomInput
                              label="Votes"
                              value={votes || ""}
                              onChange={(e) => {
                                setVotes(e.target.value);
                              }}
                              fullWidth
                              type="number"
                            />
                          </Grid>
                          <Grid item sm style={{ marginTop: 10 }}>
                            <Button
                              variant="outlined"
                              onClick={() => {
                                handleAdd();
                                setAmount('');
                                setVotes('')
                              }}
                            >
                              Add
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItem>
                    </List>
                    <Button variant="contained" color="primary" disabled={!selectedCountry.name || voteOptions.length === 0} className={classes.createButton}
                      onClick={() => {
                        handleConfigured()
                        setPaymentConfig({
                          ...paymentConfig,
                          changed: true
                        })
                      }
                      }>Configure</Button>
                  </Grid>
                )}
              {paymentConfig.methods &&
                paymentConfig.methods.indexOf("MobileMoney") > -1 && (
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{
                      border: "1px solid black",
                      borderRadius: 5,
                      marginBottom: 5,
                      height: "fit-content",
                    }}
                  >
                    Mobile money Configure Countries
                    <br />
                    <Grid item xs={12} md={12}>
                      <List className={classes.batchesList}>
                        {configured && configured.map((batch, index) => (
                          <>
                            <ListItem key={index} className={classes.listItem}>
                              <ListItemText
                                style={{ justifyContent: "space-between" }}
                                primary={`${batch.name}`}
                                secondary={
                                  <Box className={classes.Itemcontainer}>
                                    <ListSubheader className={classes.listItem}>
                                      <ListItem className={classes.childList}>
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            handleCollapse(index);
                                          }}
                                        >
                                          <ListItemText
                                            primary={`Vote Options`}
                                          />
                                          {index === selectedIndex ? (
                                            <ExpandMore style={{ cursor: "pointer" }} />
                                          ) : (
                                            <ExpandLess style={{ cursor: "pointer" }} />
                                          )}
                                        </div>
                                      </ListItem>
                                      <Collapse
                                        in={index === selectedIndex}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List
                                          dense="dense"
                                          component="div"
                                          disablePadding
                                        >
                                          {batch.voteOptions.length > 0 && batch.voteOptions.map((item, itemIndex) => {
                                            return (
                                              <ListItem
                                                button
                                                key={itemIndex}
                                                className={classes.nested}
                                              >
                                                <ListItemText
                                                  primary={`${batch.currency}: ${item.amount} - ${item.votes} vote(s)`}
                                                />
                                              </ListItem>
                                            );
                                          })}
                                        </List>
                                      </Collapse>
                                    </ListSubheader>
                                  </Box>
                                }
                              />
                              <ListItemSecondaryAction
                                onClick={() => handleRemoveCountry(index)}
                              >
                                <Tooltip title={`Remove country`} arrow>
                                  <Remove fontSize="small" color="action" />
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider />
                          </>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                )}
            </Grid>
          </ListItem>
          <ListItem>
            <Grid
              container
              spacing={2}
              // justify="space-around"
              style={{ marginTop: 10 }}
            >
              {paymentConfig.methods &&
                (paymentConfig.methods.indexOf("card") > -1) && (
                  <Grid
                    item
                    md={6}
                    sm={12}
                    style={{ border: "1px solid black", borderRadius: 5 }}
                  >
                    <Typography style={{ marginBottom: 10 }}>
                      Card options
                    </Typography>

                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="cardCurrency">
                        Supported currency
                      </InputLabel>
                      <Select
                        labelId="cardCurrency"
                        id="cardCurrency"
                        name="cardCurrency"
                        label="Currency"
                        multiple
                        value={selectedValues}
                        onChange={handleChangeCurrency}
                        renderValue={(selected) =>
                          selected.map((value) => currencyConfig && selectedValues.concat(currencyConfig).find((option) => option.code === value).code).join(', ')
                        }
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {currencyConfig && currencyConfig.map((item, index) => {
                          return (<MenuItem key={index} value={item.code}>
                            <Checkbox
                              className={classes.checkBox}
                              checked={selectedValues.indexOf(item.code) > -1}
                            // checked={item.voteOptions && item.voteOptions.length > 0}
                            />
                            <ListItemText primary={item.code} />
                          </MenuItem>)
                        })}
                      </Select>
                    </FormControl>
                    {selectedValues.length > 0 && (
                      <ListItem style={{ marginTop: 10 }}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            aria-label="card payment configuration"
                            defaultValue="RWF"
                            name="cardPaymentConfig"
                            value={state.activeTab}
                            row
                            onChange={(e) => {
                              setState({
                                activeTab: e.target.value,
                              });
                            }}
                          >
                            {selectedValues.map((el) => {
                              return (
                                <FormControlLabel
                                  value={el}
                                  control={
                                    <Radio
                                      className={classes.checkBox}
                                      checked={state.activeTab === el}
                                    />
                                  }
                                  label={`${el} options`}
                                />
                              )
                            })}
                          </RadioGroup>
                        </FormControl>
                      </ListItem>
                    )}
                    {state.activeTab && (
                      <>
                        <List>
                          <ListItem>
                            <Typography>Votes options</Typography>
                          </ListItem>
                          {currencyConfig && selectedValues.concat(currencyConfig).find((option) => option.code === state.activeTab).voteOptions.map(
                            (el, index) => (
                              <ListItem
                                key={index}
                                style={{ paddingTop: 0, paddingBottom: 0 }}
                              >
                                <ListItemText
                                  primary={`> ${state.activeTab} ${el.cardAmount} : ${el.cardVotes} votes `}
                                  style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                  }}
                                />
                                <ListItemSecondaryAction
                                  onClick={() => {
                                    handleRemoveCardOption(el, index)
                                  }}
                                >
                                  <Tooltip
                                    title={`Remove ${el.cardVotes} option`}
                                    arrow
                                  >
                                    <Remove fontSize="small" color="action" />
                                  </Tooltip>
                                </ListItemSecondaryAction>
                              </ListItem>
                            )
                          )}
                          <ListItem>
                            <Grid container spacing={1}>
                              <Grid item sm>
                                <CustomInput
                                  label="Amount"
                                  value={cardAmount || ""}
                                  onChange={(e) => {
                                    setCardAmount(e.target.value);
                                    const newVoteOptions = [...currencyConfig.find((currency) => currency.code === state.activeTab).voteOptions];
                                    newVoteOptions[index].cardAmount = e.target.value;
                                    handleConfigureVoteOptions(state.activeTab, newVoteOptions);
                                  }}
                                  fullWidth
                                  type="number"
                                  StartAdornment={() => `${state.activeTab}:`}
                                />
                              </Grid>
                              <Grid item sm>
                                <CustomInput
                                  label="Votes"
                                  value={cardVotes || ""}
                                  onChange={(e) => {
                                    setCardVotes(e.target.value);
                                    const newVoteOptions = [...currencyConfig.find((currency) => currency.code === state.activeTab).voteOptions];
                                    newVoteOptions[index].cardVotes = e.target.value;
                                    handleConfigureVoteOptions(state.activeTab, newVoteOptions);
                                  }}
                                  fullWidth
                                  type="number"
                                />
                              </Grid>
                              <Grid item sm style={{ marginTop: 10 }}>
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    handeCardCurrencies(state.activeTab);
                                    setPaymentConfig({
                                      ...paymentConfig,
                                      changed: true
                                    })
                                    setCardAmount('');
                                    setCardVotes('');
                                  }}
                                >
                                  Add
                                </Button>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </>
                    )}
                  </Grid>
                )}
            </Grid>
          </ListItem>
        </React.Fragment>
      )}
    </div>
  );
};
