import reducers from "./reducers";

const eventsReducers = {
  allEvents: reducers.allEventsReducer,
  singleEvent: reducers.singleEventReducer,
  pendingEvents: reducers.pendingEventsReducer,
  eventStatistics: reducers.eventStatisticsReducer,
  offlineTickets: reducers.eventPrintOfflineTickets,
  donwloadTIckets: reducers.downloadOfflineTickets,
  offlineTicketBatches: reducers.offlineTicketsBatches,
  uplodInvitationsFile: reducers.uploadInvitations,
  generateInvitations: reducers.generatedInvitations,
  invitationBatches: reducers.invitationBatches,
  paymentProviders: reducers.paymentProvidersReducer,
};

export { eventsReducers };
