import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { fullUrlAction } from "../../../redux/action";
import { OFFLINE_BATCH_TICKETS } from "../../../redux/events/types";
import "date-fns";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { primaryColor } from "../../../assets/styles/base";
import "../../../assets/styles/plugins/calendar.css";
import { Grid, ListSubheader } from "@material-ui/core";
import Loading from "../../../components/isLoading";
import Toast from "../../../components/MessageToast";
import fullUrlApiService from "../../../services/fullUrlService";
import TextField from "@material-ui/core/TextField";

const userStyles = makeStyles((theme) => ({
  card: {
    padding: 10,
    width: "100%",
  },
  createButton: {
    backgroundColor: primaryColor[0],
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: primaryColor[0],
    },
  },

  checkBox: {
    "&.Mui-checked": {
      color: primaryColor[0],
    },
  },
  TicketsInput: {
    display: "flex",
    width: "100%",
    "@media(max-width:1300px)": {
      flexDirection: "column",
      margin: "10px 0px 10px 0px",
      width: "100%",
    },
  },
  listItem: {
    paddingRight: "0px",
    paddingLeft: "0px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  childList: {
    paddingRight: "0px",
    paddingLeft: "0px",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  Itemcontainer: {
    maxWidth: "85%",
    "@media(max-width: 600px)": {
      maxWidth: "100%",
    },
  },
  batchesList: {
    width: "500px",
    "@media(max-width: 600px)": {
      width: "100%",
    },
  },
  actions: {
    display: "block",
    "@media(max-width:600px)": {
      display: "none",
    },
  },
}));

export default ({ event, apiCall }) => {
  const classes = userStyles();
  const [state, setState] = useState({
    loading: false,
  });

  const [tickets, setTickets] = useState([]);
  const generatedTickets = useSelector((store) => store.offlineTickets);
  const downloadTickets = useSelector((store) => store.donwloadTIckets);
  const currentUser = useSelector((store) => store.currentUser);
  const [ticketsConfig, setTicketsConfig] = useState({
    required: true,
    currency: event.payment.currency || [],
    ticketsOptions: event.payment.ticketsOptions || [],
  });
  const [currency, setCurrency] = useState({ currency: "RWF" });
  const [nav, setNav] = useState(false);
  const batches = useSelector((state) => state.offlineTicketBatches);

  const onInputTextChange = (tier, quantity, tierCurrency, amount) => {
    const newTickets = tickets;
    const index = newTickets.findIndex(
      (el) => el.name === tier.name && el.currency === tierCurrency
    );
    if (index > -1) {
      newTickets[index].quantity = quantity;
    } else {
      newTickets.push({
        quantity,
        currency: tierCurrency,
        name: tier.name,
        amount,
      });
    }
    setTickets(newTickets);
  };

  const [batchDetail, setBatchDetail] = React.useState([]);

  React.useEffect(() => {
    const tempBatchDetails =
      !batches.loading &&
      batches.data.results &&
      batches.data.results.map((batch) => {
        const batchDetail = [];

        for (const detail of batch.batchDetail) {
          const index = batchDetail.map((x) => x.name).indexOf(detail.name);
          if (index !== -1) {
            batchDetail[index].quantity += detail.quantity;
          } else {
            batchDetail.push(detail);
          }
        }

        batch.batchDetail = batchDetail;

        return batch;
      });
    setBatchDetail(tempBatchDetails ? tempBatchDetails : []);
  }, [batches]);
  const onGenerateTickets = async () => {
    const options = [];

    for (let op of tickets.filter((t) => t.quantity > 0)) {
      options.push({
        name: op.name,
        quantity: +op.quantity,
        amount: op.amount,
        currency: op.currency,
      });
    }
    const dataValues = {
      eventInfo: {
        eventName: event.name,
        eventId: event.id,
        eventPhoto: event.coverPhoto,
        eventTime: new Date(event.startDate).toLocaleTimeString(),
        eventDate: event.startDate,
        organizer: event.organizer,
        eventDescription: event.description,
        eventVenue: event.venue,
      },
      ticketsOptions: tickets.filter((t) => t.quantity > 0),
    };

    setState((prev) => ({ ...prev, loading: true }));
    const response = await fullUrlApiService({
      path: `/tickets/generate_offline`,
      method: "POST",
      data: dataValues,
    });
    Toast({
      message: response.data,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        setState((prev) => ({ ...prev, loading: false }));
        if (!response.error) {
          // reset field
        }
      },
    });
  };
  useEffect(() => {
    nav &&
      !generatedTickets.loading &&
      generatedTickets.data.status === 201 &&
      Toast({
        message: `${generatedTickets.data.message}`,
        type: "success",
      });
    nav &&
      !generatedTickets.loading &&
      generatedTickets.data.status !== 201 &&
      Toast({
        message: `${generatedTickets.data.message}`,
        type: "error",
      });
    setNav(false);
  }, [!generatedTickets.loading && nav]);

  const onDownloadTickets = async (batchId) => {
    Toast({
      message:
        "We are generating your tickets, don't close the tab until you get notified...",
      type: "warning",
      duration: 10000,
    });
    const response = await fullUrlApiService({
      path: `${process.env.TICKETS_GENERATOR_API}/tickets/offline?eventId=${event.id}&batchId=${batchId}&email=${currentUser.email}`,
      method: "GET",
    });
    Toast({
      message: response.message,
      type: response.error ? "error" : "success",
      duration: 4000,
      onClose: () => {
        if (!response.error) {
          window.open(response.results.link, "_blank");
        }
      },
    });
  };

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    dispatch(
      fullUrlAction({
        method: "GET",
        path: `/events/${event.id}/batch_tickets`,
        actionType: OFFLINE_BATCH_TICKETS,
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const [collapse, setCollapse] = React.useState(true);
  const [selectedIndex, setSelectedIndex] = React.useState(true);

  const handleCollapse = (index) => {
    if (index === selectedIndex) {
      setSelectedIndex("");
      setCollapse(!collapse);
    } else {
      setSelectedIndex(index);
      setCollapse(!collapse);
    }
  };

  return (
    <Card className={classes.card}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: "10px" }}
      >
        <Grid item>
          <Typography variant="h6" align="start">
            Event Offline Tickets
          </Typography>
        </Grid>
      </Grid>
      <br></br>
      <Grid container justifyContent="space-between">
        <Grid
          item
          sm
          style={{
            border: "1px solid black",
            borderRadius: 5,
            height: "fit-content",
            padding: 6,
            margin: 6,
            width: "100%",
          }}
        >
          <Grid
            container
            justifyContent="center"
            direction="column"
            spacing={2}
          >
            {state.loading ? (
              <div
                style={{
                  backgroundColor: "white",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Loading />{" "}
                <Typography>
                  Please wait while we generate your Tickets
                </Typography>
              </div>
            ) : (
              ticketsConfig.ticketsOptions.map((op) => (
                <Grid item style={{ width: "100%" }}>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      justify: "space-between",
                      alignItems: "start",
                      flexDirection: "column",
                    }}
                  >
                    <Typography style={{ padding: "10px" }}>
                      {op.name} Tickets
                    </Typography>
                    <Grid item className={classes.TicketsInput}>
                      {op.prices.map((elt) => (
                        <TextField
                          fullWidth
                          variant="outlined"
                          style={{
                            margin: "2px",
                          }}
                          id={`${op.name}-${elt.currency}`}
                          name={`${op.name}-${elt.currency}`}
                          onChange={(e) =>
                            onInputTextChange(
                              op,
                              +e.target.value,
                              elt.currency,
                              +elt.amount
                            )
                          }
                          type="number"
                          label={`${elt.currency}`}
                          placeholder="Number of tickets"
                        />
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}
            <Grid item justifyContent="flex-end">
              <Button
                variant="contained"
                color="primary"
                className={classes.createButton}
                disabled={state.loading || apiCall.loading}
                onClick={onGenerateTickets}
              >
                {state.loading ? "Generating..." : "Generate Tickets"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          sm
          style={{
            border: "1px solid black",
            borderRadius: 5,
            marginBottom: 5,
            padding: 10,
            width: "100%",
          }}
        >
          <List disablePadding>
            <ListSubheader>
              <Typography variant="subtitle1">Tickets Options</Typography>
            </ListSubheader>
            {ticketsConfig.ticketsOptions.map((el) => (
              <ListItem style={{ paddingTop: 0, paddingBottom: 0 }}>
                <ListItemText
                  primary={`> ${el.name} `}
                  secondary={
                    <React.Fragment>
                      <Typography variant="body2">
                        {`${el.prices
                          ?.map((p) => `${p.currency} ${p.amount}`)
                          .join(", ")}`}
                      </Typography>
                      {`${el.totalPlaces || 0} Tickets`}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Card>
  );
};
